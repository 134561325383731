.TopSideMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: white;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    display: flex; 
    z-index: 1;

    .button {
        width: 50px;
        flex-shrink: 0;
        background: none;
        outline: none;
        border: none;
        color: #444444;
        cursor: pointer;
        font-size: 22px;
    }
}