.Question {
    padding-left: 20px;
    text-align: left;
    margin-bottom: 20px;

    textarea {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
        resize: vertical;
        margin-top: 10px;
        width: -webkit-calc(100% - 20px);
        width:    -moz-calc(100% - 20px);
        width:         calc(100% - 20px);

        height: 100px;
        font-size: 20px;
    }
}