@import url(https://fonts.googleapis.com/css?family=Roboto:500);
body {
  margin: 0 0 55px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.BottomNavbar{position:fixed;bottom:0;width:100%;height:55px;box-shadow:0 0 3px rgba(0,0,0,0.2);background-color:white;display:flex;overflow-x:auto}.BottomNavbar .navLink{display:flex;flex-direction:column;align-items:center;justify-content:center;flex-grow:1;min-width:50px;overflow:hidden;white-space:nowrap;font-size:13px;color:#444444;text-decoration:none;-webkit-tap-highlight-color:transparent;transition:background-color 0.1s ease-in-out}.BottomNavbar .navLink:hover{background-color:#eeeeee}.BottomNavbar .navLink_Active{color:#1569C7}.BottomNavbar .navIcon{font-size:18px}

.TopSideMenu{position:fixed;top:0;left:0;width:100%;height:50px;background:white;box-shadow:0 3px 3px rgba(0,0,0,0.1);display:flex;z-index:1}.TopSideMenu .button{width:50px;flex-shrink:0;background:none;outline:none;border:none;color:#444444;cursor:pointer;font-size:22px}

.nav .navLinks{position:fixed;top:0;left:0;z-index:2;height:100vh;width:250px;background:#ffffff;transform:translateX(-250px);transition:transform 0.3s;overflow:scroll}.nav .navLinks h2{display:flex;align-items:center;color:#666666;padding:12px 15px;background:transform 0.1s}.nav .navLinks .weekLink{display:flex;align-items:center;color:#666666;font-weight:bold;font-size:14px;text-decoration:none;padding:12px 15px;background:transform 0.1s}.nav .navLinks .navIcon{font-size:18px;margin-right:1vh}.nav .navLinks .active{color:#2E86C1;background:#F8F9F9}.nav .navLinks .weekLink:hover{background:#D7DBDD}.nav .navLinks .button_cont{height:14.5%;position:relative}.nav .navLinks .logOutBtn{position:absolute;bottom:20px;left:15px;color:#494949 !important;text-decoration:none;background:#ffffff;padding:5px;border:2px solid #494949 !important;font-size:14px}.nav .navOverlay{z-index:1;position:fixed;top:0;left:0;width:100vw;height:100vh;background:rgba(0,0,0,0.2);-webkit-backdrop-filter:blur(1px);backdrop-filter:blur(1px);visibility:hidden;opacity:0;transition:opacity 0.1s}.navOpen .navLinks{transform:translateX(0);box-shadow:0 0 15px rgba(0,0,0,0.2)}.navOpen .navOverlay{visibility:visible;opacity:1}


.ProfilePage{height:100%;display:flex;justify-content:center;align-items:center}.ProfilePage .card{width:100%;max-width:33rem}

.Plan .containerList{margin-top:70px}.Plan .containerList .listItem{cursor:pointer;text-align:left;display:flex;align-items:center}.Plan .containerList .listItem .dayName{min-width:170px}.Plan .containerList .listItem .active{display:inline}.Plan .containerList .listItem .nonactive{display:none}

.Week01{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Question{padding-left:20px;text-align:left;margin-bottom:20px}.Question textarea{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;resize:vertical;margin-top:10px;width:calc(100% - 20px);height:100px;font-size:20px}

.Paragraph p{text-align:justify;margin:20px}

.Title h3{text-align:left;padding-left:20px}

.LessonHeader{position:relative}.LessonHeader .lessonImage{width:100%}.LessonHeader .lessonTitle{position:absolute;text-align:left;top:10px;left:10px;padding-top:18px;max-width:360px;background-color:rgba(0,0,0,0.5);color:white;padding-left:10px;padding-right:10px}.LessonHeader .lessonTitle h1{right:20px}

.Week02{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Illustration img{width:100%}

.Week03{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week04{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week05{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week06{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week07{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week08{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week09{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week10{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week11{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.Week12{margin-top:60px;margin-bottom:80px;-webkit-column-width:400px;column-width:400px;-webkit-column-fill:auto;column-fill:auto}

.LoggedOut{width:100%;height:100%}.LoggedOut .google-btn{position:absolute;top:50%;left:50%;margin-left:-100px;margin-top:-21px;width:200px;height:42px;background-color:#4285f4;border-radius:2px;box-shadow:0 3px 4px 0 rgba(0,0,0,0.25)}.LoggedOut .google-btn .google-icon-wrapper{position:absolute;margin-top:1px;margin-left:1px;width:40px;height:40px;border-radius:2px;background-color:#fff}.LoggedOut .google-btn .google-icon{position:absolute;margin-top:11px;margin-left:-10px;width:18px;height:18px}.LoggedOut .google-btn .btn-text{float:right;margin:11px 11px 0 0;color:#fff;font-size:14px;letter-spacing:0.2px;font-family:"Roboto"}.LoggedOut .google-btn:hover{box-shadow:0 0 6px #4285f4}.LoggedOut .google-btn:active{background:#1669F2}

