.ProfilePage {
    height: 100%;
    display: flex;
    justify-content:center;
    align-items: center;

    .card {
        width: 100%;
        max-width: 33rem; 
    }
}