.Plan {
    .containerList {
        margin-top: 70px;

        .listItem {
            cursor: pointer;
            text-align: left;
            display: flex;
            align-items: center;

            .dayName{
                min-width: 170px;
            }
            
            .active{
                display: inline;
            }

            .nonactive {
                display: none;
            }
        }
    
    }
}