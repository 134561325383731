.LoggedOut {
    $white: #fff;
    $google-blue: #4285f4;
    $button-active-blue: #1669F2;

    width: 100%;
    height: 100%;

    .google-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -100px;
        margin-top: -21px;
        
        width: 200px;
        height: 42px;
        background-color: $google-blue;
        border-radius: 2px;
        box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);

        .google-icon-wrapper {
            position: absolute;
            margin-top: 1px;
            margin-left: 1px;
            width: 40px;
            height: 40px;
            border-radius: 2px;
            background-color: $white;
        }

        .google-icon {
            position: absolute;
            margin-top: 11px;
            margin-left: -10px;
            width: 18px;
            height: 18px;
        }

        .btn-text {
            float: right;
            margin: 11px 11px 0 0;
            color: $white;
            font-size: 14px;
            letter-spacing: 0.2px;
            font-family: "Roboto";
        }

        &:hover {
            box-shadow: 0 0 6px $google-blue;
        }

        &:active {
            background: $button-active-blue;
        }
    }

    @import url(https://fonts.googleapis.com/css?family=Roboto:500);
}

