.LessonHeader {
    position: relative;

    .lessonImage{
        width: 100%;
    }

    .lessonTitle {
        position: absolute;
        text-align: left;
        top: 10px;
        left: 10px;
        padding-top: 18px;
        max-width: 360px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding-left: 10px;
        padding-right: 10px;

        h1 {
            right: 20px;
        }
    }
}